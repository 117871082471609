/**
 * Source: https://github.com/vercel/next.js/blob/72f5c93aaded6b5310e9d8796857768c5432b4f2/examples/with-aphrodite/pages/index.js
 */
import { StyleSheet } from "aphrodite";
import "../css/blog.css";
import "../css/demos/highlights.css";
import "../css/demos/mini-schematic.css";
import "../css/demos/mini-table.css";
import "../css/global.css";
import "../css/main.css";
import "../css/typography.css";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { trackPageView } from "../demos/analytics";

// Rehydrate to ensure that the client doesn't duplicate styles.
// It has to execute before any code that defines styles.
// '__REHYDRATE_IDS' is set in '_document.js'.
if (typeof window !== "undefined") {
  StyleSheet.rehydrate((window as any).__REHYDRATE_IDS);
}

// Initialize Mixpanel.
if (typeof window !== "undefined") {
  mixpanel.init("d7bdc2065990fc941fa287257a65ff56", {
    api_host: "https://analytics-proxy.spr.ng",
    // Disable geolocation and some other forms of tracking.
    ip: false,
    save_referrer: false,
    // Use localStorage over cookies, to protect caching.
    persistence: "localStorage",
  });
}

function handleTrackPageView() {
  trackPageView({
    hash: window.location.hash,
    host: window.location.host,
    hostname: window.location.hostname,
    href: window.location.href,
    origin: window.location.origin,
    pathname: window.location.pathname,
    port: window.location.port,
    protocol: window.location.protocol,
    search: window.location.search,
  });
}

export default function App({ Component, pageProps }: any) {
  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeComplete", handleTrackPageView);
    return () => router.events.off("routeChangeComplete", handleTrackPageView);
  }, [router.events]);
  useEffect(handleTrackPageView, []);
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      "\n" +
        "   @@@@@@@@@@@     @@@@@@@@@@@@    @@@@@@@@@@@@@     @@@    @@@@        @@@      @@@@@@@@@@@@       \n" +
        "  @@@       @@@    @@@       @@@   @@@        @@@    @@@    @@@@@@      @@@    @@@@              @@@\n" +
        "  @@@@@            @@@       @@@   @@@        @@@    @@@    @@@ @@@@    @@@   @@@              @@@@ \n" +
        "     @@@@@@@@@     @@@@@@@@@@@@    @@@@@@@@@@@@@     @@@    @@@   @@@@  @@@   @@@        @@@@@@@    \n" +
        " @@@        @@@    @@@             @@@    @@@@       @@@    @@@     @@@@@@@    @@@          @@@@    \n" +
        "  @@@@     @@@@    @@@             @@@      @@@@     @@@    @@@       @@@@@     @@@@@    @@@@@      \n" +
        "    @@@@@@@@@      @@@             @@@        @@@@   @@@    @@@         @@@        @@@@@@@@\n" +
        "\n" +
        "\n" +
        "Giving scientists superpowers.\n" +
        "\n" +
        "springdiscovery.com/careers",
    );
  }, []);
  return <Component {...pageProps} />;
}
