/**
 * Interface to tracking user analytics (a facade over Mixpanel).
 */
import * as Sentry from "@sentry/nextjs";
import mixpanel from "mixpanel-browser";

type Event =
  | { id: "Toggle accessibility mode" }
  | {
      id: "Click MegaMap cell";
      DemoID: string;
    }
  | {
      id: "Click MegaMap column";
      DemoID: string;
    }
  | {
      id: "Click MegaMap row";
      DemoID: string;
    }
  | {
      id: "Click MiniMap cell";
      DemoID: string;
    }
  | {
      id: "Select query option";
      DemoID: string;
    }
  | {
      id: "Toggle image manipulation slider";
      DemoID: string;
    }
  | {
      id: "Toggle image manipulation channel";
      DemoID: string;
    }
  | { id: "Toggle scoring methodology slider"; DemoID: string }
  | { id: "Toggle preset scoring methodology"; DemoID: string }
  | { id: "Toggle explained cell property"; DemoID: string }
  | { id: "Toggle sorted cell property"; DemoID: string }
  | { id: "Click replay button"; DemoID: string }
  | { id: "Click timeline play button"; DemoID: string }
  | { id: "Click timeline pause button"; DemoID: string }
  | { id: "Click Drawings button"; DemoID: string }
  | { id: "Click Photos button"; DemoID: string }
  | { id: "Click Novel Drug label"; DiseaseModel: string; DemoID: string };

/**
 * Track a page view.
 */
export function trackPageView(properties: { [K: string]: string }) {
  try {
    mixpanel.track("Page view", properties);
  } catch (error) {
    // Consider Mixpanel errors to be non-fatal, but report them to Sentry.
    Sentry.captureException(error);
  }
}

/**
 * Track an analytics event.
 */
export function trackEvent(event: Event): void {
  const { id, ...properties } = event;
  try {
    mixpanel.track(id, properties);
  } catch (error) {
    // Consider Mixpanel errors to be non-fatal, but report them to Sentry.
    Sentry.captureException(error);
  }
}
